import React, { useState, useRef } from 'react';
import './FormComponent.css';

const PrivacyInfo = () => {

    return (
      <div className="info-container">
        <h1>Datenschutzerklärung zur TAP-Studie</h1>
        <p>
        Diese Datenschutzerklärung klärt dich über die Art, den Umfang und Zweck der Verarbeitung von personenbezogenen Daten im Rahmen der Studie auf. Diese Seite hat zum Ziel, dir einen Einblick dazu zu geben, wie wir sicherstellen, dass unser Umgang mit Ihren persönlichen Daten im Einklang mit den Bestimmungen der Datenschutzgrundverordnung (DSGVO) ist. Im Hinblick auf die verwendeten Begrifflichkeiten, wie z.B. „Verarbeitung“ oder „Verantwortlicher", verweisen wir auf die Definitionen im Art. 4 der DSGVO.
        </p>

        <h4>Verantwortliche</h4>
        <p>
        Wir sind Psycholog:innen, die an der Universität Basel an der Abteilung für Persönlichkeits- und Entwicklungspsychologie arbeiten. Unsere Studie wird vom Schweizerischen Nationalfonds finanziert und wird im Rahmen des Projekts Zeit, Person, Verhalten“ (Projektnummer xxx) durchgeführt.
        </p>
        
        <p>
        Anschrift: <br></br>
        TAP-Studie <br></br>
        Persönlichkeits- und Entwicklungspsychologie <br></br>
        Fakultät für Psychologie <br></br>
        Universität Basel <br></br>
        Missionsstrasse 62 <br></br>
        CH-4055 Basel
        </p>

        <p>
        E-Mail: TAP-study.psychologie@unibas.ch <br></br>
        Web: <a target='_blank' href='https://psychologie.unibas.ch/de/fakultaet/abteilungen/entwicklungs-und-persoenlichkeitspsychologie'>https://psychologie.unibas.ch/de/fakultaet/abteilungen/entwicklungs-und-persoenlichkeitspsychologie/</a>
        </p>

        <h4>Welche personenbezogenen Daten werden erfasst und verarbeitet?</h4>
        <p>
        Wir erheben ausschliesslich persönliche Informationen, die unverzichtbar für unsere Studie sind. Sämtliche Informationen, die wir erfassen, erhalten wir direkt von dir. Hierzu zählen:
        </p>
        <ul>
          <li>Allgemeine Personendaten (z.B. Alter, Geschlecht, Ausbildung, Familienstand, Haupttätigkeit, Wohnsituation, Mobiltelefonnummer)</li>
          <li>Angaben zu typischem Verhalten, Werthaltungen und Einstellungen (z.B. Persönlichkeit, Lebenszufriedenheit)</li>
        </ul>
        
        <h4>Wie nutzen wir Ihre persönlichen Informationen?</h4>
        <ul>
          <li>Die Informationen, die wir von dir erhalten, dienen dazu, unsere derzeitigen Forschungsfragen zu beantworten. Das heisst konkret, dass wir und unsere Studierenden mithilfe der erhobenen Daten Master- und Doktorarbeiten sowie Fachartikel verfassen. Deine Daten können aber auch dazu beitragen, zukünftige Fragestelllungen zu beantworten, die bisher noch nicht von uns oder von anderen Wissenschaftler:innen betrachtet wurden.</li>
          <li>Wir nutzen Ihre persönlichen Angaben auch dafür, dass du reibungslos an der Studie teilnehmen kannst, sofern du uns hierfür Ihr Einverständnis gegeben haben (z.B. Zustellen von Umfragelinks etc.).</li>
          <li>Ihre persönlichen Daten verwenden wir somit unter anderem um ...</li>
          <ul>
            <li>... wissenschaftliche Forschung zu betreiben.</li>
            <li>... Qualifikationsarbeiten zu verfassen.</li>
            <li>... mit Ihnen über Ihre Teilnehme an der Studie zu kommunizieren.</li>
            <li>... zu überprüfen, ob Sie sich für den Erhalt eines Gutscheins qualifiziert haben.</li>
          </ul>
          <li>Wenn wir deine Daten für wissenschaftliche Forschungszwecke verwenden, berichten wir keine Einzelfälle, sondern ziehen Schlussfolgerungen über alle Teilnehmenden oder über eine Teilgruppe der Teilnehmenden (z.B. Frauen/Männer). Aus den Analysen und den Ergebnissen der Studien kann demnach nicht auf eine Einzelperson geschlossen werden und die Ergebnisse lassen sich auch nicht auf Einzelpersonen übertragen.</li>
        </ul>

        <h4>Wie verwalten wir Ihre persönlichen Informationen?</h4>
        <ul>
          <li>Wir schützen alle persönlichen Informationen, die wir von dir erhalten. Das Computernetzwerk der Universität Basel in der Schweiz ist sicher und wir bewahren die persönlichen Daten unserer Teilnehmenden auf diesen Servern auf. Nur die Studienleitenden können über Passwort-geschützte Computer auf diese Daten zugreifen.</li>
          <li>Wir behalten Ihre persönlichen Informationen nur so lange, wie es nötig ist, um den zuvor beschriebenen Nutzen zu erfüllen und um in Einklang mit den Bestimmungen der Universität Basel zu bleiben. Wir bewahren niemals mehr persönliche Informationen als nötig auf. Nachdem unser Forschungsprojekt abgeschlossen ist, werden wir nur Datensätze bewahren, die von uns bereinigt wurden. Dies bedeutet, dass sämtliche Informationen, die Rückschluss auf deine Person zulassen (z.B. Telefonnummer), von den bereinigten Datensätzen entfernt werden.</li>
          <li>Deine persönlichen Daten werden wir niemals verkaufen oder mit Drittparteien für Werbezwecke teilen.</li>
          <li>Es ist möglich, dass wir deine Daten im Sinne einer transparenten wissenschaftlichen Forschung anderen Forscher:innen über das Internet zugänglich machen. Hierzu zählen beispielsweise Studierende, die unserem Forschungsprojekt angehören oder aber auch kollaborierende Wissenschaftler:innen von anderen Institutionen. Wenn wir dies tun, entfernen wir alle Informationen, die einen Rückschluss auf dich als Person zulassen. Deine Telefonnummer wird niemandem ausserhalb des Studienteams weitergegeben und dienen nur für Zwecke der Kontaktaufnahme und nicht der Forschung.</li>
          <li>Es ist notwendig, dass einige wenige Drittanbieter Zugang zu ausgewählten persönlichen Daten haben, damit Sie uns mit Ihren Serviceleistungen bei der Durchführung unserer Studie unterstützen können. Im Konkreten beinhaltet dies:</li>
          <ul>
            <li>Um Sie per SMS über neue Umfragen zu benachrichtigen, nutzen wir den Dienst Twilio. Abgesehen von den Mobiltelefonnummern werden aber keinerlei weitere Informationen an Twilio übertragen. Um mehr über die Datenschutzrichtlinien von Twilio zu erfahren, klicken Sie <a target='_blank' href='https://www.twilio.com/legal/privacy#how-twilio-processes-your-end-users-personal-information'>hier</a></li>
          </ul>
        </ul>

        <h4>Ihre Rechte</h4>
        <p>
        Die folgenden Rechte werden im Sinne von Kap. 3 DSGVO gewährleistet (Anfragen per E-Mail: TAP-study.psychologie@unibas.ch):
        </p>
        <ul>
          <li>Auskunftsrecht</li>
          <li>Recht auf Datenübertragbarkeit</li>
          <li>Korrektur der eigenen Daten</li>
          <li>Löschung der personenbezogenen Daten</li>
          <li>Einschränkung der Datenverarbeitung</li>
          <li>Genereller Widerspruch zur Datenverarbeitung</li>
        </ul>

        <h4>Information über das Recht, die Zustimmung zu widerrufen</h4>
        <p>
        Die Teilnahme an der Studie ist freiwillig. Die Entscheidung zur Teilnahme kann jederzeit widerrufen werden, ohne dass Konsequenzen oder Nachteile entstehen. Die Verarbeitung der personenbezogenen Daten vor dem Widerruf der Zustimmung bleibt rechtmässig.
        </p>

        <h4>Informationen über die Aufsichtsbehörde</h4>
        <p>
        Die teilnehmenden Personen haben das Recht, sich im Falle von Datenschutzverletzungen etc. bei der Datenschutz-Aufsichtsbehörde zu beschweren.
        </p>

        <h4>Kontaktangaben der Datenschutz-Aufsichtsbehörde</h4>
        <p>
        Datenschutzbeauftragter des Kantons Basel-Stadt <br></br>
        Henric Petri-Strasse 15 <br></br>
        Postfach 205 <br></br>
        4010 Basel <br></br>
        <a target='_blank' href='https://www.dsb.bs.ch/'>https://www.dsb.bs.ch/</a>
        </p>

        <h4>Name und Kontaktangaben der Datenschutzbeauftragten</h4>
        <p>
        Universität Basel <br></br>
        Datenschutz <br></br>
        Petersgraben 35, Postfach <br></br>
        4001 Basel <br></br>
        E-Mail: datenschutz@unibas.ch <br></br>
        Web: <a target='_blank' href='https://www.unibas.ch/de/Universitaet/Administration-Services/Generalsekretariat/Datenschutz.html'>https://www.unibas.ch/de/Universitaet/Administration-Services/Generalsekretariat/Datenschutz.html</a>
        </p>

        <br></br>
        <p>
        Diese Datenschutzerklärung wurde am 5. Oktober 2024 erstellt. Es kann sein, dass wir von Zeit zu Zeit weitere Aktualisierungen vornehmen, um dich über Änderungen in unserer Vorgehensweise zu informieren oder weil sich die rechtliche Lage verändert hat. Aktualisierungen findest du stets auf dieser Seite oder wir informieren dich direkt.
        </p>
      </div>
    );
  }

export default PrivacyInfo;
